<template>
    <div class="amount-input-container">
      <div class="input-group">
        <label for="inputAmount">请输入充值金额</label>
        <input 
          type="number" 
          id="inputAmount" 
          v-model="inputAmount" 
          :disabled="isGameIdTwo || isGameIdOne"
          placeholder="输入金额，只能充值2000元以下金额"
          @input="validateInputAmount"
          min="1" 
          step="1"
        >
      </div>
      <button @click="submitAmount" class="submit-button">确定</button>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import md5 from 'js-md5';
  
  export default {
    data() {
      return {
        inputAmount: '',
        isGameIdTwo: false,
        isGameIdOne: false,
        gameid: null,
        id: null,
        urlAmount: null
      };
    },
    async mounted() {
      const urlParams = new URLSearchParams(window.location.search);
      this.gameid = urlParams.get('gameid');
      this.id = urlParams.get('id');
      this.urlAmount = urlParams.get('amount');

      if (this.gameid === '2') {
        this.inputAmount = '100';
        this.isGameIdTwo = true;
      } else if (this.gameid === '1' && this.urlAmount) {
        this.inputAmount = this.urlAmount;
        this.isGameIdOne = true;
      }
    },
    methods: {
      validateInputAmount() {
        if (!this.isGameIdTwo && !this.isGameIdOne) {
          let value = parseInt(this.inputAmount);
          if (isNaN(value) || value < 1) {
            this.inputAmount = '1';
          } else if (value > 2000) {
            this.inputAmount = '2000';
          }
        }
      },
      async submitAmount() {
        try {
          const key = process.env.VUE_APP_API_KEY;
          const timestamp = new Date().getTime();
          const token = md5(timestamp + key);
          //const response = await axios.post('http://localhost:5053/api2/Pay', {
            const response = await axios.post('https://page.tlbb.pw/api2/Pay', {
            amount: this.inputAmount,
            id: this.id,
            token: token,
            gameid: this.gameid,
            timestamp: timestamp
          });
          console.log('创建订单响应:', response.data);
          const payUrl = response.data.data.payurl;
          if (payUrl) {
            window.location.href = payUrl;
          } else {
            console.error('未获取到有效的支付链接');
          }
        } catch (error) {
          console.error('创建订单失败:', error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .amount-input-container {
    text-align: center;
    padding: 20px;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0px 3px 6px 0px #232B321A;
    margin: 20px auto;
    width: calc(100% - 40px);
  }
  .input-group {
    margin-bottom: 15px;
  }
  .input-group label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-weight: 600;
    font-size: 1.2rem;
  }
  .input-group input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.8rem;
  }
  .submit-button {
    background: #3F9FFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.8rem;
  }
  .submit-button:hover {
    background: #2e86d9;
  }
  </style>